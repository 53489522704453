<template>
  <div class="wrapper"></div>
</template>

<script>
export default {
  name: "AdminVerifyLogin",
  title: "Admin - Verify Login",
  computed: {
    verifyStatus() {
      return this.$store.getters["auth/verifyStatus"];
    }
  },
  methods: {
    checkVerified() {
      if (this.verifyStatus == "verified") {
        this.$store.commit("auth/setLoading", false);
        this.$router.push("/admin").catch(() => {});
      }
    }
  },
  watch: {
    verifyStatus() {
      this.checkVerified();
    }
  },
  mounted() {
    this.checkVerified();
  }
};
</script>
